import { sha256 } from 'ohash'

/**
   * Generates an avatar image based on an email address.
   * @returns An object containing the image numbers for the avatar.
   * @example
   * import { useAvatar } from 'path/to/file'
   *
   * const { avatar, email, makeAvatar } = useAvatar()
   *
   * email.value = 'example@example.com'
   * console.log(avatar.value) // { topRightImage: 5, topLeftImage: 9, bottomImage: 3 }
   */
export const useAvatar = () => {
  const email = ref('')

  const makeAvatar = (email: string) => {
    const hash = sha256(email)
    const topRightImage = parseInt(hash.substring(0, 8), 16) % 12 + 1
    const topLeftImage = parseInt(hash.substring(8, 16), 16) % 12 + 1
    const bottomImage = parseInt(hash.substring(16, 24), 16) % 12 + 1
    return {
      topRightImage,
      topLeftImage,
      bottomImage
    }
  }
  const avatar = computed(() => {
    return makeAvatar(email.value)
  })
  return { avatar, email, makeAvatar }
}
